const preOfertaConstants = {
    // SPEI: 'SPEI_DOMICILIADO',
    // ODP: 'ORDEN_DE_PAGO',
    // CHECK: 'CHEQUE',
    // SPEI: 'DirectDeposit',
    // ODP: 'PaymentOrder',
    // CHECK: 'Sucursal',

    SPEI: 'DirectDeposit',
    SPEI2: 'DirectNoDeposit',
    ODP: 'PaymentOrder',
    CHECK: 'Sucursal',
}

export default preOfertaConstants